<template>
	<div v-loading.fullscreen.lock="fullscreenLoading">
		<homeHeader />
		<search />

		<div class="detail">
			<header>
				<img :src="info.image" />
			</header>
			<aside>
				<div class='showAd'></div>
				<div class="container-fluid">
					<div class="collection-add">ساقلىۋېلىش</div>
					<div class="like-add">L</div>
					<div class="like-add">S</div>
					<div class="comment">C</div>
				</div>
				<div class="download" v-if="!info.is_vip">چۈشۈرۈش</div>
				<div class="downloadPremium" v-if="info.is_vip">
					<p>چۈشۈرۈش</p>
					<span>ئالاھىدە ئەزا بولۇش</span>
				</div>
				<div class="content">
					ماتېرىيال فورماتى .{{info.type}} شەكىلدە، چۈشۈرگەندە فورماتىغا دىققەت قىلىپ چۈشۈرۈپ ئىشلىتىڭ.
				</div>
				<div class="userInfo">
					<router-link :to="'/abduweli'" target="_blank">
						<div class="user">
							<div class="avatar"><img :src="user.useravatar" referrerpolicy='no-referrer' /></div>
							<div class="info">
								<div class="username">{{user.username}}</div>
								<div class="works">{{user.works}} دانە ماتېرىيال</div>
							</div>
						</div>
					</router-link>
					<div class="follow">ئەگىشىش</div>
				</div>
			</aside>

			<footer>
				<div class="showname">
					{{info.title}}
				</div>
				<div class="demo">
					<div class="fleximage-item" v-for="(item,index) in list" :key="index" :data-w="item.width"
						:data-h="item.height" :style="'background-color:'+item.bgcolor+' ;'">
						<router-link :to="{name: 'show', params: { id: item.id }}" @click.native="flushCom()">
							<img :src="item.image" referrerpolicy='no-referrer'>
						</router-link>
						<div class="info">
							<div class="name">{{item.title}}</div>

							<div class="userInfo">
								<router-link :to="'abduweli'" target="_blank">
									<div class="user">
										<div class="avatar">
											<img :src="item.image" />
										</div>
										<div class="username">abduweli</div>
									</div>
								</router-link>
								<div class="like">45</div>
							</div>
						</div>
						<div class="premium" v-if="item.is_vip"></div>
					</div>
				</div>
			</footer>
		</div>

	</div>
</template>


<script>
	import {
		show
	} from '@/api/admin/sucai'
	import {
		FlexImage
	} from '@/assets/flexImage.js'
	import homeHeader from '@/components/homeHeader.vue'
	import search from '@/components/search.vue'
	export default {
		name: 'show',
		components: {
			homeHeader,
			search
		},
		data() {
			return {
				fullscreenLoading: false,
				articleId: 0,
				info: {},
				list: [],
				user: {}
			}
		},
		methods: {
			flushCom: function() {
				//router是路由实例,例如:var router = new Router({})
				//router.go(n)是路由的一个方法，意思是在history记录中前进或者后退多少步，0就表示还是当前，类似window.history.go(n)
				this.$router.go(0);

			}
		},


		created() {
			this.fullscreenLoading = true
			this.articleId = this.$route.params.id
			show({
				id: this.articleId
			}).then(res => {
				if (res.status == 200) {
					this.fullscreenLoading = false
					this.info = res.data
					this.user = res.data.user
					this.list = res.data.content
				}
			}).catch(err => {
				console.log('fucker', err);
			})
		},

		updated() {
			var list = this.list
			if (list.length > 0) {
				FlexImage(document.querySelector('.demo'), {
					rowHeight: 250,
					listenResize: true
				});
			}
		}


	}
</script>
<style>
	.detail {
		width: 1400px;
		margin: auto;
		grid-template-areas:
			'header aside'
			'footer footer';
		grid-template-columns: 1fr 360px;
		display: grid;
		direction: ltr;
	}

	.detail header {
		grid-area: header;
		background-color: #f8fafb;
		position: relative;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.detail header img {
		max-width: calc(100vh - 184px);
		display: block;
	}

	.detail aside {
		grid-area: aside;
		border-bottom: 1px solid #cfd9e0;
		border-color: #e3e9ed;
	}

	.detail aside .showAd {
		width: 360px;
		height: 280px;
		overflow: hidden;
		margin-bottom: 20px;
	}

	.detail aside .container-fluid {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 20px 10px;
	}

	.detail aside .container-fluid .collection-add {
		box-shadow: inset 0 0 0 2px #cfd9e0;
		color: #374957;
		height: 35px;
		line-height: 35px;
		border-radius: 3px;
		width: 185px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 10px;
	}

	.detail aside .container-fluid .like-add {
		box-shadow: inset 0 0 0 2px #cfd9e0;
		color: #374957;
		height: 35px;
		line-height: 35px;
		width: 35px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 10px;
		border-radius: 3px;
	}

	.detail aside .container-fluid .comment {
		box-shadow: inset 0 0 0 2px #cfd9e0;
		color: #374957;
		height: 35px;
		line-height: 35px;
		width: 35px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 3px;
	}

	.detail aside .download {
		background-color: #2bc48a;
		height: 45px;
		line-height: 45px;
		color: #fff;
		margin: 0 20px 10px;
		border-radius: 3px;
		text-align: center;
		cursor: pointer;
	}
	
	.detail aside .downloadPremium {
		background-color: #fca120;
		height: 45px;
		color: #fff;
		margin: 0 20px 10px;
		border-radius: 3px;
		text-align: center;
		cursor: pointer;
		overflow: hidden;
	}
	.detail aside .downloadPremium p {
		height: 20px;
		padding: 0;
		margin: 5px 0 0;
	}
	.detail aside .downloadPremium span{
		height: 20px;
		display: block;
		font-size: 12px;
	}

	.detail aside .download:hover {
		background: #00af80;
	}
	
	.detail aside .downloadPremium:hover {
		background-color: #f69110;
	}

	.detail aside .content {
		overflow: hidden;
		direction: rtl;
		padding: 10px 20px 10px;
		margin-top: 20px;
		border-top: 1px solid #cfd9e0;
		border-color: #e3e9ed;
		line-height: 25px;
	}

	.detail aside .userInfo {
		overflow: hidden;
		padding: 20px 20px;
		border-top: 1px solid #cfd9e0;
		border-color: #e3e9ed;
		direction: rtl;
		display: grid;
		grid-template-columns: 1fr 130px;
		column-gap: 10px;
		align-items: center;
	}

	.detail aside .userInfo a {
		color: #0f73ee;
		text-decoration: none;
		font-weight: bold;
	}

	.detail aside .userInfo a:hover {
		color: #0d60c8;
	}

	.detail aside .userInfo .user {
		overflow: hidden;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.detail aside .userInfo .user .avatar {
		width: 45px;
		height: 45px;
		border-radius: 50%;
		overflow: hidden;
	}

	.detail aside .userInfo .user .avatar img {
		width: 100%;
		height: 100%;
		display: block;
	}

	.detail aside .userInfo .user .info {
		overflow: hidden;
		padding-right: 10px;
		max-width: calc(100% - 54px);
	}

	.detail aside .userInfo .user .info .username {
		overflow: hidden;
		font-size: 14px;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.detail aside .userInfo a .user .info .works {
		color: #5f7d95;
		font-size: 12px;
		font-weight: normal;
	}

	.detail aside .userInfo .follow {
		border: 2px solid #1273eb;
		color: #1273eb;
		border-radius: 3px;
		height: 35px;
		line-height: 35px;
		text-align: center;
		cursor: pointer;
	}

	.detail aside .userInfo .follow:hover {
		background: rgba(1, 97, 205, 0.1);
		border-color: #0161cd;
		color: #0161cd;
	}

	.detail footer {
		overflow: hidden;
		grid-area: footer;
		padding: 10px 0 30px;
		direction: rtl;
	}

	.detail footer .showname {
		overflow: hidden;
		font-size: 16px;
		height: 45px;
		line-height: 45px;
		padding: 0 20px;
	}

	.demo {
		overflow: hidden;
	}

	.fleximage-item {
		float: right;
		margin: 5px;
		position: relative;
		cursor: pointer;
		overflow: hidden;
		border-radius: 3px;
	}

	.fleximage-item .info {
		overflow: hidden;
		background: linear-gradient(rgba(8, 25, 43, 0), rgba(8, 25, 43, 0.85)) top;
		position: absolute;
		bottom: 0px;
		right: 0px;
		left: 0px;
		z-index: 1;
		display: none;
		color: #fff;
		padding: 10px;
		transition: all ease 0.3s;
	}

	.fleximage-item .info .name {
		overflow: hidden;
		height: 30px;
		line-height: 30px;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.fleximage-item .info .userInfo {
		overflow: hidden;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.fleximage-item .info .userInfo .user {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		cursor: pointer;
	}

	.fleximage-item .info .userInfo .user .avatar {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background-color: #fff;
	}

	.fleximage-item .info .userInfo .user .avatar img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		display: block;
	}

	.fleximage-item .info .userInfo .user .username {
		margin-right: 5px;
		overflow: hidden;
	}

	.fleximage-item .info .userInfo a {
		color: #fff;
		text-decoration: none;
	}

	.fleximage-item:hover .info {
		display: block;
		transition: all ease 0.3s;
	}

	.fleximage-item .premium {
		position: absolute;
		top: 10px;
		left: 10px;
		z-index: 1;
		padding: 7px;
		border-radius: 3px;
		background-color: rgba(8, 25, 43, 0.65);
		cursor: pointer;
	}

	.fleximage-item .premium::before {
		width: 14px;
		height: 14px;
		background-size: 14px 14px;
		display: block;
		background: url('../../assets/pro.svg') center center no-repeat;
		background-size: 16px 16px;
		content: '';
	}


	.fleximage-item img {
		border-radius: 3px;
	}
</style>
