<template>
		<div class="topSearch">
			<router-link to="/" class="logo"><img src="../assets/GulumColour.svg" /></router-link>
			<div class="search"></div>
		</div>
</template>

<script>
	export default {
		name: 'homeHeader',
		props: {

		},
		data() {
			return {

			}
		},
		mounted() {

		}
	}
</script>

<style>
	.topSearch {
		background-color: #fff;
		overflow: hidden;
		position: sticky;
		top: 0;
		margin-top: 0;
		padding: 10px 20px;
		border-bottom: 1px solid #e3e9ed;
		z-index: 5;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.topSearch .logo img {
		overflow: hidden;
		width: 120px;
		display: block;
	}

	.topSearch .search {
		overflow: hidden;
		height: 45px;
		background-color: #e2e8ec;
		width: 100%;
		margin-right: 20px;
		border-radius: 3px;
		box-shadow: inset 0 0 0 1px #cfd9e0;
	}
</style>
